#logo_kb {
  width: 46px;
  height: 27px;
}

.icone_menu {
  text-align: center;
  margin-top: 2vh !important;
}

.MuiListItemIcon-root {
  margin: 0 auto;
  min-width: auto !important;
}

.active svg {
  fill: #0d57a4;
}

.MuiList-padding {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.profile-menu {
  left: 72px;
  background: white;
  height: 56px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  min-width: 150px;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: none;
  position: fixed;
  overflow: hidden;
  padding: 5px 15px;

  span {
    &:hover {
      opacity: .7;
      cursor: pointer;
    }
  }

  &:hover {
    display: flex !important;
  }
}

.profile-button {
  position: relative;

  &:hover {
    .profile-menu {
      display: flex !important;
    }
  }
}
