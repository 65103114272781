// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;900&display=swap');

:root {
  --blue_kb: #0F5EAD;
  --arancio_kb: #FBB03B;
  --gray-text: #939598;
}

$colors: "blue_kb" #0F5EAD,
"blue" #0F5EAD,
"arancio_kb" #FBB03B,
"orange" #FBB03B,
"rosso" #dc3545,
"gray-text" #939598,
"odd" #f3f3f3;

.orange_line {
  border-bottom: 5px solid var(--arancio_kb);
  min-width: 100px;
  max-width: 100px;
}

@each $i in $colors {
  .btn-#{nth($i, 1)} {
    background-color: nth($i, 2) !important;
    border-color: nth($i, 2) !important;
    color: white !important;

    .fa, .fas, .far, a {
      color: white !important;
    }

    .dropdown-item {
      color: black !important;
    }

    &:focus {
      box-shadow: none
    }

    &:hover {
      color: nth($i, 2) !important;
      background-color: white !important;
      border: 1px solid nth($i, 2);

      .dropdown-item {
        color: black !important;
        background-color: white;

        &:hover {
          background-color: nth($i, 2) !important;
          color: white !important;
        }
      }

      .fa, .fas, .far, a {
        color: nth($i, 2) !important;
      }
    }
  }
  .outline-#{nth($i, 1)} {
    color: nth($i, 2) !important;
    background-color: transparent;
    background-image: none;
    border-color: nth($i, 2) !important;

    .fa, .fas, .far, a {
      color: nth($i, 2) !important;
    }

    &:hover {
      color: white !important;
      background-color: nth($i, 2) !important;
      border-color: nth($i, 2) !important;

      .fa, .fas, .far, a {
        color: white !important;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }
  .badge-#{nth($i, 1)} {
    background-color: nth($i, 2) !important;
    border: 1px solid nth($i, 2) !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: .5px;
  }
  .badge-#{nth($i, 1)}-lighten-5 {
    background-color: lighten(nth($i, 2), 5) !important;
    border: 1px solid lighten(nth($i, 2), 5) !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: .5px;
  }
  .badge-#{nth($i, 1)}-lighten-10 {
    background-color: lighten(nth($i, 2), 10) !important;
    border: 1px solid lighten(nth($i, 2), 10) !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: .5px;
  }
  .badge-outline-#{nth($i, 1)} {
    //background-color: $grigio-chiaro !important;
    color: nth($i, 2) !important;
    border: 1px solid nth($i, 2) !important;
    font-weight: 600;
    letter-spacing: .5px;

    &:hover {
      background-color: nth($i, 2) !important;
      color: white !important;
      cursor: pointer;
    }
  }
  .status-#{nth($i, 1)} {
    border-color: nth($i, 2) !important;
  }
  .bg-#{nth($i, 1)} {
    background-color: nth($i, 2) !important;
  }
  .color-#{nth($i, 1)} {
    color: nth($i, 2) !important;
  }
  .#{nth($i, 1)}_btn {
    background: nth($i, 2) !important;
    color: #fff !important;
    font-weight: 600 !important;
    border-radius: 20px !important;
    text-transform: uppercase !important;
/*    padding: 5px 15px !important;*/
    font-size: 12px !important;
    letter-spacing: 1px !important;

    &:hover {
      color: #fff !important;
      background: nth($i, 2) !important;
    }
  }
}


body {
  background: #E6E9F0 !important;
}

h1, h2, h3, h4, h5 {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
}

h3 {
  font-size: 1.5rem !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.root-container {
  font-family: 'Open Sans', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.2;
}

// Tree checkbox
.rct-text {
  align-items: baseline;
}

.rct-collapse.rct-collapse-btn {
  top: -1px;
  position: relative;
}

// Spinner
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: black;
  display: flex;
  align-content: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-style: italic;
  flex-wrap: wrap;
  opacity: .75;
  z-index: 10000;

  img {
    height: 30vh;
  }
}

.white_container {
  background: #fff !important;
  border-radius: 10px !important;
  padding: 30px !important;
  box-shadow: 3px 3px 10px #ccc !important;
}

.white_container_without_padding {
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: 3px 3px 10px #ccc !important;
}

.empty_btn {
  color: var(--blue_kb) !important;
  border: 1px solid var(--blue_kb) !important;
  border-radius: 20px !important;
  opacity: 1 !important;
  text-transform: uppercase !important;
  padding: 5px 15px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  letter-spacing: 1px !important;
}

.empty_btn:hover {
  color: var(--blue_kb) !important;
  background: #e1eaf2 !important;
}

.larger_plus {
  font-size: 16px !important;
  margin-right: 10px !important;
}


.MuiAvatar-colorDefault {
  background-color: var(--blue_kb) !important;
}

