// SOVRASCRITTURA DELLO STYLE DELLA LIBRERIA PER TOGLIERE BORDI E SHADOW AI BUTTONS E CAMBIARE SFONDO DROPDOWN

.buttons---btn-border---1O58b {
	background-color: transparent !important;
	border-color: transparent !important;
	background-image: none !important;
	transition: all 0.2s linear 0s !important;
}

.dropdown---dropdown-toggle---vMtjL.buttons---btn---2jZHN.buttons---btn-md---HRR_F.buttons---btn-border---1O58b.buttons---dropdown-toggle---vMtjL {
	box-shadow: none !important;
	text-decoration: none !important;
	outline: none !important;
	border: none !important;
}

.dropdown---dropdown-menu---1fkH0 {
	background-color: #fafafa !important;
}

#menu-container {
	margin-left: 18px;
}
