.header-commessa {
	border: 1px solid #343a40;
	border-radius: 5px;
	background: blue;
}

.header-commessa-ref {
	background: url('../../../assets/img/banner.png');
	padding-left: 60px;
	padding-right: 60px;
	background-size: cover;
	justify-content: center;
}

main {
	padding: 0 !important;
}

.name {
	color: whitesmoke;
	font-size: 16px;
	font-weight: 600;
}

.desc {
	color: rgb(157, 195, 230);
	padding-right: 5px;
	font-weight: bold;
	font-size: 16px;
}

.t-header {
	border-right: 1px solid white;
	padding: 0 70px;
	margin: 40px 0;

	> div {
		margin: 5px 0;
	}

	&:last-of-type {
		border-right: 0;
	}
}

@media screen and (min-width: 1441px) and (max-width: 1800px) {
	.header-commessa-ref {
		padding-left: 20px;
		padding-right: 20px;

		.t-header {
			padding: 0 40px;
		}
	}
}

@media screen and (max-width: 1440px) {
	.header-commessa-ref {
		padding-left: 15px;
		padding-right: 15px;

		.t-header {
			padding: 0 20px;
		}
	}
}

@media screen and (min-width: 1241px) and (max-width: 1439px) {
	.header-commessa-ref {
		.t-header {
			padding: 0 15px;
		}
	}
}

@media screen and (max-width: 1240px) {
	.header-commessa-ref {
		.t-header {
			padding: 0 10px;
		}
	}
}
#layout-commessa-content {
	padding: 10px 30px;
}
